
/* CTA
--------------------------------------------------------------------------------------------*/


[data-s-type="maps"] {
    
    .ac_maps_container {
        width: 100%;
        height: 400px;

    }
        //map fixes
        #map{
          .gm-bundled-control{
            top: 50%!important;
            transform: translateY(-50%);
          }
        }
    /*--------------------------------------------------------------------------------------------*/


} // end of scope


