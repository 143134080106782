
/* AC_S_CTA - GLOBALS
--------------------------------------------------------------------------------------------*/
.ac_cta_container {
    max-width: $ac-cta-max-width;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 $default-padding;

    @if $ac-cta-reversed-content == true {
        flex-direction: row-reverse;
    }

    p {
        line-height: 1.5;
        text-align: left;
    }
}   

.ac_cta_column {
    @include breakpoint(medium) { 
        @if $ac-cta-elements-no-margin == true {
            h2,h3,h4,p,.button {
                margin-bottom: 0;
            }
        }
        .button {
            margin-right: $default-padding / 2;
            margin-bottom: 0px;
        }
    }

    img {

        @if $ac-img-border-bottom == true {
            border-bottom: $ac-img-border; // fallback
        }

        max-width: 220px; // was 100%;
    }

    a {
      margin-right: 0!important;
      padding-left: 0!important;
      display: flex;
      justify-content: center;
      flex-grow: 0;
      text-align: center;
      position: relative;
      .button-txt{
        display: inline-block;
        min-width: 150px;
      }
      .svg-container{
        margin: 0;
      }
    }
}   


