
/* AC_S_HERO - GLOBALS
--------------------------------------------------------------------------------------------*/

//
//      CONTAINER
//

.ac_hero_container {

    @if $hero-gradient-before == true {
            
        $gradient-degrees: 0deg;
        
        @if $hero-gradient-before-position == 'bottom' {
            $gradient-degrees: 180deg;
        }

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: $hero-gradient-before-height;
            background: linear-gradient($gradient-degrees, rgba($hero-gradient-color, 0), rgba($hero-gradient-color, 1));
            z-index: 1;
            @if $hero-gradient-before-position == 'bottom' {
                bottom: 0;
            }
        }
    }
    margin: 0 auto;
    height: $hero-height;
    min-height: $hero-min-height; 

            max-height: 640px;
    position: relative;
    display: block;
}
 
//
//      SLIDES
//

.ac_hero_slides {
    position: relative;
    z-index: 3;
    height: 100%;
    display: block;
    margin: 0 auto;

    //slick fuoc fix
    max-height: 100%;
    overflow: hidden; // overflow hidden stays intact for hero slider. This also allows for fading content in after fuoc fix.
    opacity: 0;
    transition: opacity $default-transition-speed;
    
    // drop fouc if slick is initialized
    &.slick-initialized  {
        max-height: inherit;
        opacity: 1;
    }

}
.ac_hero_slide {
    will-change: contents;
    position: relative;
}
.ac_hero_slide_inner {
    max-width: $hero-slide-max-width;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    will-change: contents;
    padding: 0 $default-padding;
    @if $hero-slide-arrows-enabled == true {
        @media screen and (min-width: $hero-slide-arrows-increase-size-from) {
            @if $hero-slide-arrows-position == 'left-and-right-centered' {
                padding: 0 $default-padding * 4;
            }
            @if $hero-slide-arrows-position == 'bottom-centered' {
                padding: 0 $default-padding;
            }
        }
    } 
}
.ac_hero_slide_content_container {
    //transform: translateY($hero-slide-content-correction);
    will-change: contents;
}

.ac_hero_slide_content {
    display: flex;
}
.ac_hero_slide_content_inner {
    display: flex;
    align-items: center;
    width: 100%;
    @if $hero-slide-align == 'center' {
        justify-content: center;
    }
    @if $hero-slide-align == 'reverse' {
      flex-direction: row-reverse;
    }
}

.ac_hero_slide_content_text {
    flex: 0 1 percentage(1);
    max-width: percentage(1);
    @include breakpoint(medium-portrait) {
        flex: 0 1 percentage($hero-slide-content-text-width);
        max-width: percentage($hero-slide-content-text-width);
    }
    @if $hero-slide-type == 'text' {
        @if $hero-slide-align == 'center' {
            text-align: center;
        }
    }

}
.ac_hero_slide_content_image {
    display: flex;
    justify-content: center;
    @if $hero-slide-type == 'text' {
        display: none;
    }
    @if $hero-slide-type == 'text-image' {
        flex: 0 1 100% - percentage($hero-slide-content-text-width);
        max-width: 100% - percentage($hero-slide-content-text-width);
        
        img {
            max-width: initial;
        }
        @if $hero-slide-image-on-small == 'none'{
            @include breakpoint(small only) {
                display: none; // image is not shown on small
            }
        }
        @if $hero-slide-image-on-small == 'absolute'{
            @include breakpoint(small only) {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: -1;
                opacity: $hero-slide-image-on-small-opacity;
                max-width: initial;
            }            
        }
    }
}


//
//      SLICK FIXES
//

.slick-dots {
    button {
        border: $hero-slide-dots-border;
        background: $hero-slide-dots-background;
    }
    .slick-active {
        button {
            border: $hero-slide-dots-border-active;
            background: $hero-slide-dots-background-active;
        }   
    }
}
.slick-list {
    height: 100%;
}
.slick-track {
    height: 100%;
}
.slick-prev,
.slick-next  {
    
    display: none !important; 

    @if $hero-slide-arrows-enabled == true {
        background: none;
        padding: 0;
        box-shadow: none;

        @if $hero-slide-arrows-enabled == true {
            width: $hero-slide-arrows-size-small;
            height: $hero-slide-arrows-size-small;
            
            @media screen and (min-width: $hero-slide-arrows-increase-size-from) {
                width: $hero-slide-arrows-size-large;
                height: $hero-slide-arrows-size-large;
            }
            svg {
                fill: $hero-slide-arrows-color;
                width: $hero-slide-arrows-size-small;
                height: $hero-slide-arrows-size-small;
                transition: all $default-transition-speed;
                @media screen and (min-width: $hero-slide-arrows-increase-size-from) {
                    width: $hero-slide-arrows-size-large;
                    height: $hero-slide-arrows-size-large;
                }
            }
        }
    } 

    @include breakpoint(large) {
        &:hover {
            svg {
                fill: $hero-slide-arrows-hover-color;
            }
        }
    }

    @media screen and (min-width: $hero-slide-arrows-enabled-from){
        display: block !important;
    }
}
@if $hero-slide-arrows-enabled == true {

    @if $hero-slide-arrows-position == 'left-and-right-centered' {
        .slick-prev {
            transform: translateY(-50%) translateX($hero-slide-arrows-size-small * 2);
            @media screen and (min-width: $hero-slide-arrows-increase-size-from) {
                transform: translateY(-50%) translateX($hero-slide-arrows-size-large * 2);
            }
            @include breakpoint(xlarge) {
                transform: translateY(-50%) translateX($hero-slide-arrows-size-large * 4);
            }
            @include breakpoint(xxlarge) {
                transform: translateY(-50%) translateX($hero-slide-arrows-size-large * 6);
            }
        }   
        .slick-next  {
            transform: translateY(-50%) translateX(-$hero-slide-arrows-size-small * 2);
            @media screen and (min-width: $hero-slide-arrows-increase-size-from) {
                transform: translateY(-50%) translateX(-$hero-slide-arrows-size-large * 2);
            }
            @include breakpoint(xlarge) {
                transform: translateY(-50%) translateX(-$hero-slide-arrows-size-large * 4);
            }
            @include breakpoint(xxlarge) {
                transform: translateY(-50%) translateX(-$hero-slide-arrows-size-large * 6);
            }
        }
    }

    @if $hero-slide-arrows-position == 'bottom-centered' {
        .slick-prev {
            top: initial;
            bottom: $hero-slide-arrows-bottom-position;
            left: 50%;
            transform: translateX(-($hero-slide-arrows-size-small * 6) - $hero-slide-arrows-size-small);
        }   
        .slick-next  {
            top: initial;
            bottom: $hero-slide-arrows-bottom-position;
            left: 50%;
            transform: translateX($hero-slide-arrows-size-small * 6);
        }
    }
}

.slick-dots {
    bottom: $hero-slide-arrows-bottom-position;
}



//
//      LINK TO CONTENT
//

.ac_hero_link_to-content {
    @if( $hero-link-to-content-enabled == true) {
        position: absolute;
        z-index: 1111;
        bottom: $hero-link-to-content-bottom;
        left: 50%;
        transform: translateX(-50%);
        @if $hero-link-to-content-enable-for-medium-portrait-down == false {
            display: none;
            @include breakpoint(medium) {
                display: block;
            }
        }
    
        @include breakpoint(large) {
            &:hover {
                cursor: pointer;
            }
        }
        .svg-container {
            width: 4em;
            height: 4em;
        }
        svg {
            width: 4em;
            height: 4em;
            fill: $hero-link-to-content-color;
            transition: all $default-transition-speed;
        }
        &:active {
            transform: translateX(-50%) translateY(2px);
        }
        &:hover {
            svg {
                fill: $hero-link-to-content-hover-color;
            }
        }
    } @else {
        display: none;
    }
}