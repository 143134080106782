/* SECTION CONTROL
--------------------------------------------------------------------------------------------*/
//[data-s-id="home-toptasks"] {
    // .ac_toptasks_item{
    //     .ac_toptasks_item_container{
    //         background: $brand-light-gray;
    //         .ac_toptasks_item_content_title{
    //             color: $brand-primary;
    //         }
    //     }
    //     &:hover{
    //         .ac_toptasks_item_container{
    //             background: $brand-white!important;
    //         }
    //     }
    // } 

[data-s-id="home-services"] {
    .ac_item_content {
        background: $brand-white;
    }
}