/* PAGE CONTROL
--------------------------------------------------------------------------------------------*/
body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  [data-f-type="footer-1"]{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}