
/* CTA
--------------------------------------------------------------------------------------------*/


[data-s-type="cta"] {
    
    //columns
    $ac-cta-max-width: $max-content-1000;
    $ac-cta-column-first-width: 1*.5; // takes any percentage calculation
    $ac-cta-column-first-text-align: 'right'; // left, right or center. 
    $ac-cta-column-second-text-align: 'left'; // left, right or center. 
    $ac-cta-reversed-content: false; // flip columns

    // column gap
    $ac-cta-column-gap-has-divider: true;
    $ac-cta-column-gap: $default-padding * 2;
    $ac-cta-column-gap-divider: solid 1px $default-border-color;

    $ac-cta-elements-no-margin: false; // prevents nasty alignment due to margins, not applicable for collapsed view.

    //img
    $ac-img-border-bottom: true;
    $ac-img-border: solid 1px $default-background-light;

    @import '../ac_s__cta-globals/__cta_globals';

    /*--------------------------------------------------------------------------------------------*/
    
    border-top: solid 1px $default-border-color;

    .ac_cta_container {
        @include breakpoint(medium) { 
            display: flex;
            align-items: center;
        }
    } 
    
    .ac_cta_column {
       .button-txt{
          width: 100%!important;
        }
        &:nth-child(1) {
            @include breakpoint(medium) { 
                text-align: right;
                @if $ac-cta-column-gap-has-divider == true {
                    padding-right: $ac-cta-column-gap;
                    border-right: $ac-cta-column-gap-divider;
                    margin-right: $ac-cta-column-gap / 2;
                    width: 100%;
                }
            }
        }
        &:nth-child(2) {
            @include breakpoint(medium) { 
                @if $ac-cta-column-gap-has-divider == true {
                    margin-left: $ac-cta-column-gap / 2;
                }
            }
        }
    }   

} // end of scope