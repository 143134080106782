
/* FOOTER 1 - 1
--------------------------------------------------------------------------------------------*/
body.home{
  [data-f-type="footer-1"] {
    margin-top: 0px;
  }
}

[data-f-type="footer-1"] {
  margin-top: 60px;

    // footer
    $footer-background: $brand-white;
    $footer-content-width: $max-content-1000;
    
    // primary container
    $footer-primary-enabled: true;
    $footer-primary-background: $brand-secondary;
    $footer-primary-padding: $default-padding * 2 $default-padding / 2;
    $footer-primary-color: $default-text-color-light;
    $footer-primary-link-color: $brand-white;
    $footer-primary-link-hover-color: $brand-primary;
    $footer-primary-column-1-enabled: true;
    $footer-primary-column-2-enabled: true;
    $footer-primary-column-3-enabled: true;
    $footer-primary-column-4-enabled: true;
    // width of columns above medium. Any combination is possible, as long as it adds up to 100
    $footer-primary-column-width-1: 1 / 6;
    $footer-primary-column-width-2: 1.5 / 6;
    $footer-primary-column-width-3: 1.5 / 6;
    $footer-primary-column-width-4: 2 / 6;
    $footer-primary-collapsed-hide-column-1: false;
    $footer-primary-collapsed-hide-column-2: false; 
    $footer-primary-collapsed-hide-column-3: false;
    $footer-primary-collapsed-hide-column-4: false;
    $footer-primary-collapsed-align-center: true;
    
    // secondary container
    $footer-secondary-enabled: true;
    $footer-secondary-background: $brand-light-gray;
    $footer-secondary-padding: $default-padding / 2 $default-padding / 2;
    $footer-secondary-color: rgba($brand-white, .5);
    $footer-secondary-link-color: rgba($brand-white, .5);
    $footer-secondary-link-hover-color: rgba($brand-white, .9);
    $footer-secondary-column-1-enabled: true;
    $footer-secondary-column-2-enabled: true;
    $footer-secondary-column-width-1: 1*.5;
    $footer-secondary-column-width-2: 1*.5;
    $footer-secondary-column-1-justify: flex-start;
    $footer-secondary-column-2-justify: flex-end;
    $footer-secondary-collapsed-align-center: true;
    
    // socials
    $footer-socials-icon-background: $brand-tertiary-darker;
    $footer-socials-icon-hover-background: $brand-white;
    $footer-socials-icon-color: $brand-white;
    $footer-socials-icon-hover-color: $brand-tertiary-darker;
    

    @import '../ac_f__footer-globals/__footer_globals';


    /*--------------------------------------------------------------------------------------------*/
    
    // globals
    @if $footer-background != transparent or $footer-background != none {
        background: $footer-background;
    }
    font-size: 90%;

    @include breakpoint(medium only) {
        padding-bottom: 80px;
    }

    @include breakpoint(medium only) {
        margin-top: 40px;
    }
    
    h2 {
        font-size: 120%;
        margin-bottom: $default-padding / 2;
        // opacity: .5;
    }

    ul {
        list-style: none;
        margin: 0;
    }

    p{
      margin: 0;
    }

    .ac_footer_primary_container{
      position: relative;
      overflow: hidden;
      .ac_footer_primary_logo-symbol{
        opacity: .2;
        position: absolute;
        right: 4rem;
        z-index: 0;
        height: 24rem;
        bottom: -2rem;
        @include breakpoint(medium up) {
          height: 100%;
        }
      }
    }


    //
    //      COLUMN
    //

    // margin fix for news items, if in second column
    .ac_footer_column {
        &:nth-child(2) {
            .ac_footer_link {
                line-height: 1.3;
                margin-bottom: $default-padding;
            }
        }
    }


.ac_footer_primary_container{
  .ac_footer_primary_column{
    &:nth-child(3){
      .ac_footer-1_editor{
        pointer-events: none;
        cursor: pointer;
        *{
          pointer-events: all;
          margin: 0;
          padding: .4rem 0;
        }
        &:hover{
          *{
            color: $footer-primary-link-hover-color!important;
          }
        }
      }
    }
  }
}


    //
    //      PRIMARY
    //

    a{
      transition: .4s color;
    }

    .ac_footer_primary_link {
        display: block;
        transition: .4s color;
    }
    .ac_footer_primary_link_copy_above {
        text-transform: uppercase;
        display: block;
        opacity: .5;
        font-size: 80%;
    }


    //
    //      SECONDARY
    //
    .ac_footer_secondary {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 90%;
        height: 40px;
    }
    .ac_footer_secondary_link {
        display: block;
    }


} // end of scope

