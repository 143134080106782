
/* HERO - 2
--------------------------------------------------------------------------------------------*/

[data-s-type="hero"] {

    // hero
    $hero-height: 60vh; // 100vh, 80vh, 400px, etc
    $hero-min-height: 400px; // optional and handy for smaller sizes, to prevent collapsing of content
    $hero-gradient-before: false;
    $hero-gradient-before-height: 25vh;
    $hero-gradient-before-position: 'top';
    $hero-gradient-color: darken($default-background-dark, 2%);

    // slide
    $hero-slide-max-width: 1000px;
    $hero-slide-content-correction: 0; // -3em // allows for visual correction of outlining content, when using a divider
    $hero-slide-type: 'text-image'; // text or text-image
    $hero-slide-align: 'left'; // center or left. reverse always implies left aligned image
    $hero-slide-content-text-width: 1*.5; // can be any percentage calculation, eg 1*.8 or 1/4. The image (if applicable) will take the space that is left
    $hero-slide-image-on-small: 'absolute'; // none or absolute; how to show the optional image in a slide on small devices
    $hero-slide-image-on-small-opacity: .4;

    // arrows
    $hero-slide-arrows-enabled: true;
    $hero-slide-arrows-enabled-from: 700px; // 700px
    $hero-slide-arrows-increase-size-from: 1150px;
    $hero-slide-arrows-size-small: 1.5em;
    $hero-slide-arrows-size-large: 2em;
    $hero-slide-arrows-position: 'bottom-centered'; // left-and-right-centered, bottom-centered
    $hero-slide-arrows-bottom-position: 10em; // if bottom-centered
    $hero-slide-arrows-color:  $default-icon-color-dark;
    $hero-slide-arrows-hover-color: rgba( $default-icon-color-dark, .5);

    // dots
    $hero-slide-dots-border: solid 2px rgba($brand-white, .2); 
    $hero-slide-dots-border-active: solid 2px rgba($brand-white, .2); 
    $hero-slide-dots-background: transparent; 
    $hero-slide-dots-background-active: $brand-white; 

    // link to content
    $hero-link-to-content-enabled: true;
    $hero-link-to-content-bottom: 3em;
    $hero-link-to-content-enable-for-medium-portrait-down: false;
    $hero-link-to-content-color: rgba($brand-primary, 1);
    $hero-link-to-content-hover-color: rgba($brand-primary, .8);


    @import '_hero_globals';


    /*--------------------------------------------------------------------------------------------*/
    

    h1, h2, h3 {
        line-height: 1;
        // color: $brand-white;
        font-weight: 600;
    }
    p {
        line-height: 1.5;
        // color: $brand-white;
    }
    
    * {
        &:focus {
            outline: none;
        }
    }

    .ac_hero_container {
        @include breakpoint(medium-portrait down){
            height: 50vh;
            min-height: 400px;
        }
    }
    
    .ac_hero_slide_content_text {
        // background: $brand-white;
        padding: $default-padding;

        @include breakpoint(large) {
            padding: 2rem;
        }

        P {
            color: $brand-white;
            text-shadow: 2px 2px 5px #000000;
        }
    }
    .ac_hero_slide_content_text_title {
        * {
            font-size: 140%;
            color: $brand-white;
            text-shadow: 2px 2px 5px #000000;
            // color: $default-text-color-light;
            @include breakpoint(medium) {
                font-size: 180%;
            }
        }
    }

    .ac_hero_slide_content_image{
      img{
        position: absolute;
        top: 3rem;
      }
    }
    

    .slick-dots {
        bottom: inherit;
        top: 2rem;
    }

    .ac_backdrop_image-container{
      max-width: 1200px;
      left: 50%;
      transform: translateX(-50%);
      &:before,
      &:after{
        content: '';
        background-image: linear-gradient(90deg, rgba($brand-light-gray, 1), rgba($brand-light-gray, 0));
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 15rem;
        z-index: 99;
      }
      &:after{
        left: auto;
        right: 0;
        transform: scaleX(-1);
      }
    }

    

} // end of scope