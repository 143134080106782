
/* SECTION - MENU 4
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/


$ac-menu-4-transparent: false; 
$ac-menu-4-transparent-on-home: false;
$ac-menu-4-z-index: 2222;
$ac-menu-4-background: $brand-white; 
$ac-menu-4-gradient-before: false;
$ac-menu-4-gradient-before-color: $default-background-dark;
$ac-menu-4-logo-width: 130px; // height for medium up. 
$ac-menu-4-content-width: 1000px;
$ac-menu-4-divider-height: 30px;

$ac-menu-4-main-background: $brand-white;
$ac-menu-4-main-content-secondary-menu-color: rgba($brand-black, .5);

$ac-menu-4-bar-bottom-enabled: true;
$ac-menu-4-bar-bottom-justify-content: flex-end;
$ac-menu-4-bar-bottom-background: $brand-primary;

//socials icon
$ac-menu-4-socials-icon-background: $default-background-light;
$ac-menu-4-socials-icon-hover-background: $default-background-dark;
$ac-menu-4-socials-icon-color: $brand-white;
$ac-menu-4-socials-icon-hover-color: $default-icon-color-light;

// mobile
$ac-menu-4-mobile-fixed: true;  
$ac-menu-4-mobile-height: 70px;
$ac-menu-4-mobile-padding: 10px;
$ac-menu-4-mobile-svg-size: 25px;
$ac-menu-4-mobile-svg-color: $default-icon-color;

// overlay
$ac-menu-4-overlay-container-background: $brand-gray;
$ac-menu-4-overlay-menu-primary-border: $default-border;
$ac-menu-4-overlay-menu-primary-text-align: center; // left or center
$ac-menu-4-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-4-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-4-overlay-backdrop-background: rgba($default-background-dark, .8);

@import '__menu_4_globals';


/*--------------------------------------------------------------------------------------------*/


[data-m-type="menu-4"] {
  max-height: 150px;
  .ac_menu-4_bar_bottom_container > a,
  .ac_menu-4_bar_bottom_container .menu-main-container > ul > li > a{
    color: $brand-white!important;
    transition: color .4s;
    font-weight: $default-font-family-body-weight-bold!important;
    &:hover{
      color: rgba($brand-white, .6)!important;
    }
  }

  .ac_menu-4_bar_bottom_container{
    display: flex;
    align-items: center;
    .v_has-icon-left{
      .svg-container{
        margin-right: .4rem!important;
      }
    }
  }

  .ac_menu-4_sticky{
    .ac_menu-4_main_content{
      display: flex;
      flex-direction: row;
      align-items: center;
      &-wrapper{
        margin-top: .6rem;
        .ac_menu-4_main_content-1{
          opacity: 0;
          transform: translateX(1rem);
          transition: transform .4s, opacity .4s;
          pointer-events: none;
          .menu-secondary-container a{
            font-weight: 600;
            border-right: 2px solid $brand-secondary;
            margin-left: 0;
            border-left: 0;
            padding-left: 0;
            padding-right: 1rem;
            margin-right: .5rem;
          }
        }
      }
    }
    &.is-stuck{
      .ac_menu-4_main_content{
        &-wrapper{
          .ac_menu-4_main_content-1{
            transform: translateX(0);
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }

  .ac_menu-4_main_content{
    display: flex;
    flex-direction: row;
    align-items: center;
    &-wrapper{
      display: flex;
      .ac_menu-4_main_content-1{
        display: flex;
        position: relative;
      }
    }

    > a{
      margin-top: .6rem;
      transition: color .4s;
      font-weight: 600;
      color: $brand-primary;
      > span{
        margin-right: .4rem!important;
      }
      &:hover{
        color: $brand-secondary-darker;
      }
    }
    
    .ac_menu-4_search_container{
      form{
        padding: 0;
      }
    }
    .ac_menu-4_btn-search{
      transition: color .4s;
      &:hover{
        color: $brand-secondary-darker;
      }
      svg{
        margin-left: .5rem;
        use{
          stroke-width: 5px;
          stroke: currentColor;
        }
      }
    }
    .menu-secondary-container a{
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 2px solid $brand-secondary;
      transition: color .4s;
      &:hover{
        color: $brand-secondary-darker;
      }
    }
  }

  .ac_menu-4_mobile_content{
    .ac_menu-4_search_container{
      transform: translateY(-1rem);
      right: 0;
      top:100%;
      form{
        padding: 0;
      }
    }
  }
}



