
/* AC_S_TOPTASKS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-toptasks-items-max-width: $max-content-1200;
$ac-toptasks-items-padding: 0;
$ac-toptasks-items-inner-wrapper-enabled: false;
$ac-toptasks-items-inner-wrapper-padding: $default-padding / 4;
$ac-toptasks-items-inner-wrapper-background-color: $brand-lightest-gray;
$ac-toptasks-items-have-border-dividers: false;

// item
$ac-toptasks-item-padding: $default-padding / 2;
$ac-toptasks-item-container-padding: $default-padding;
$ac-toptasks-item-has-shadow: false;
$ac-toptasks-item-background-color: $brand-white;
$ac-toptasks-item-background-hover-color: $brand-white;
$ac-toptasks-item-border-radius: $default-border-radius * 2; 

// image
$ac-toptasks-item-has-image-container: true; 
$ac-toptasks-item-has-image-container-height: 120px;
$ac-toptasks-item-has-image-container-background-color: $brand-primary-darker;
$ac-toptasks-item-has-image: false;
$ac-toptasks-item-has-image-initial-fade: .1;
$ac-toptasks-item-has-image-hover-fade: .5;

// icon
$ac-toptasks-item-icon-enbled: true;
$ac-toptasks-item-icon-width: 80px; // px of em
$ac-toptasks-item-icon-padding: 12px; // px or em - is deducted from icon width. Say width is 80px and padding is 15px, the actual icon is 50px;
$ac-toptasks-item-icon-margin-bottom: 40px; 
$ac-toptasks-item-icon-bg: transparent;
$ac-toptasks-item-icon-hover-bg: transparent;
$ac-toptasks-item-icon-fill: $brand-white;
$ac-toptasks-item-icon-hover-fill: rgba($brand-white, .5);

// title
$ac-toptasks-item-title-color: $brand-primary;
$ac-toptasks-item-title-color-hover: rgba($brand-primary, .6);

// description
$ac-toptasks-item-description-enabled: true;
$ac-toptasks-item-description-color: rgba($default-text-color-light, .6);
$ac-toptasks-item-description-color-hover: rgba($default-text-color-light, .6);
$ac-toptasks-item-description-margin-bottom: 0;

// btn-container
$ac-toptasks-item-btn-container-enabled: false;
$ac-toptasks-item-btn-container-color: $default-text-color;
$ac-toptasks-item-btn-container-color-hover: rgba($default-text-color, .9);

@import '__toptasks_globals';

/*--------------------------------------------------------------------------------------------*/


.ac_toptasks_item {
    @include breakpoint(medium-portrait only) { 
        width: 100%;
        max-width: 100%;
    }
    &:after {
        @include breakpoint(medium-portrait only) { 
            @include ac-toptask-border-divider-bottom;
        }
    }
}

.ac_toptasks_item_container {
    display: flex;
    overflow: hidden;
    align-items: center;
    transition: all .3s ease;
    @include breakpoint(large) {
        &:hover {
            box-shadow: 4px 4px 10px rgba(black, .2);
            transform: translateY(-3px);
        }
    }
}

.ac_toptasks_item_image-container {
    display: block;
    width: $ac-toptasks-item-has-image-container-height;
    height: 100%;
    background: $ac-toptasks-item-has-image-container-background-color;
    border-top-left-radius: $ac-toptasks-item-border-radius;
    border-top-right-radius: 0;
    border-bottom-left-radius: $ac-toptasks-item-border-radius;
    border-bottom-right-radius: 0;
}

.ac_toptasks_item_icon-container {
    flex: 0 0 $ac-toptasks-item-has-image-container-height;
    margin-bottom: 0;
}
.ac_toptasks_item_content {
    
}
.ac_toptasks_item_content_title {
    margin-bottom: 0;
}